import React from 'react'
import { ScaleLoader } from "react-spinners";

const Loading = () => {
    return (
        <div className="flex items-center justify-center h-[100vh]">
            <ScaleLoader
                size={120} // Size of the loader
                color={"#01012D"} // Color of the loader
                // loading={isLoading} // Pass the loading state
            />
        </div>
    )
}

export default Loading
