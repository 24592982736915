import MacIcon from "../assets/icons/Apple.png";
import WindowsIcon from "../assets/icons/Windows.png";
import AndroidIcon from "../assets/icons/Android.png";
import IPhoneIcon from "../assets/icons/IOS.png";
import ChromeIcon from "../assets/icons/Chrome.png";
import FireFoxIcon from "../assets/icons/Firefox.png";
import LinuxIcon from "../assets/icons/Linux.png";
import GoogleIcon from "../assets/icons/google_store.png";
import AppIcon from "../assets/icons/app_store.png";
import SmileSvg from "../assets/svg/smile.svg";
import FingerSvg from "../assets/svg/finger.svg";
import CheckSvg from "../assets/svg/check.svg";
import FacebookSvg from "../assets/svg/facebook.svg";
import TwitterSvg from "../assets/svg/twitter.svg";
import InstagramSvg from "../assets/svg/instagram.svg";
import DiscordSvg from "../assets/svg/discord.svg";
import LinkedinSvg from "../assets/svg/linkedin.svg";

export const photo_data = [
  "/assets/image/BG-2.png",
  "/assets/image/BrighterVPN_Illustration-1.png",
  "/assets/image/BrighterVPN_Illustration-2.png",
  "/assets/image/BrighterVPN_Illustration-3.png",
  "/assets/image/BrighterVPN_Illustration-4.png",
  "/assets/image/BrighterVPN-Trust.png",
  "/assets/image/vpn-server.png",
  "/assets/image/logo.png",
  "/assets/image/pure-vpn-logo.png",
  "/assets/image/logo-blue.png",
  "/assets/image/effect.png",
];

export const free_brighter_vpn_data = [
  "6,000+ VPN Servers",
  "80+ Locations in 65+ Countries",
  "Easy to use VPN app for all your devices",
  "Crypto-first for added anonymity and privacy",
];

export const header_itmes = [
  {
    label: "What is VPN",
    link: "/what-is-vpn",
  },
  {
    label: "Apps",
    link: "",
  },
  {
    label: "Pricing",
    link: "/pricing",
  },
  {
    label: "Servers",
    link: "/servers",
  },
];

export const systems = [
  {
    icon: MacIcon,
    label: "Mac",
  },
  {
    icon: WindowsIcon,
    label: "Windows",
  },
  {
    icon: AndroidIcon,
    label: "Android",
  },
  {
    icon: IPhoneIcon,
    label: "iPhone",
  },
  {
    icon: IPhoneIcon,
    label: "iPad",
  },
  {
    icon: ChromeIcon,
    label: "Chrome",
  },
  {
    icon: FireFoxIcon,
    label: "FireFox",
  },
  {
    icon: LinuxIcon,
    label: "Linux",
  },
];

export const users_section_data = [
  {
    status: "Invited",
    content: "I got a problem and Peter resolves it. Thanks a lot",
    day_before: 2,
  },
  {
    status: "Invited",
    content: "I got a problem and Peter resolves it. Thanks a lot",
    day_before: 2,
  },
  {
    status: "Invited",
    content: "I got a problem and Peter resolves it. Thanks a lot",
    day_before: 2,
  },
  {
    status: "Invited",
    content: "I got a problem and Peter resolves it. Thanks a lot",
    day_before: 2,
  },
  {
    status: "Invited",
    content: "I got a problem and Peter resolves it. Thanks a lot",
    day_before: 2,
  },
  {
    status: "Invited",
    content: "I got a problem and Peter resolves it. Thanks a lot",
    day_before: 2,
  },
];

export const servers_data = [
  {
    count: 65,
    label: "Countries",
  },
  {
    count: 6000,
    label: "VPN servers",
  },
  {
    count: 80,
    label: "Locations",
  },
];

export const stack_data = [
  {
    icon: SmileSvg,
    label: "3 Million+ Satisfied Users",
  },
  {
    icon: FingerSvg,
    label: "One Click Software",
  },
  {
    icon: CheckSvg,
    label: "No Log Policy",
  },
];

export const footer_apps_links = {
  title: "BrighterVPN Apps",
  content: [
    {
      label: "Mac VPN",
      link: "",
    },
    {
      label: "Linux VPN",
      link: "",
    },
    {
      label: "iPhone VPN",
      link: "",
    },
    {
      label: "Huawei VPN",
      link: "",
    },
    {
      label: "Android VPN",
      link: "",
    },
    {
      label: "VPN Chrome Extension",
      link: "",
    },
    {
      label: "VPN FireFox Extension",
      link: "",
    },
    {
      label: "VPN Edge Extension",
      link: "",
    },
    {
      label: "Android TV VPN",
      link: "",
    },
    {
      label: "Firestick TV VPN",
      link: "",
    },
    {
      label: "Apple TV VPN",
      link: "",
    },
  ],
};

export const footer_setup_links = {
  title: "VPN Setup",
  content: [
    {
      label: "Routers VPN",
      link: "",
    },
    {
      label: "DDWRT Applet",
      link: "",
    },
  ],
};

export const footer_brightervpn_links = {
  title: "BrighterVPN",
  content: [
    {
      label: "What is a VPN?",
      link: "",
    },
    {
      label: "Benefits",
      link: "",
    },
    {
      label: "Trust Center",
      link: "",
    },
    {
      label: "Blog",
      link: "",
    },
  ],
};

export const footer_addons_links = {
  title: "VPN Add-ons",
  content: [
    {
      label: "Dedicated IP VPN",
      link: "",
    },
    {
      label: "Port Forwarding",
      link: "",
    },
  ],
};

export const footer_tools_links = {
  title: "Tools",
  content: [
    {
      label: "What is My IP",
      link: "",
    },
    {
      label: "DNS LeaK Test",
      link: "",
    },
    {
      label: "IPv6 Leak Test",
      link: "",
    },
    {
      label: "WebRTC Leak Test",
      link: "",
    },
  ],
};

export const footer_discover_links = {
  title: "Discover",
  content: [
    {
      label: "BrighterKeep",
      link: "",
    },
    {
      label: "BrighterEncrypt",
      link: "",
    },
    {
      label: "BrighterPrivacy",
      link: "",
    },
    {
      label: "Brightversity",
      link: "",
    },
  ],
};

export const footer_about_links = {
  title: "About BrighterVPN",
  content: [
    {
      label: "Pricing",
      link: "",
    },
    {
      label: "Features",
      link: "",
    },
    {
      label: "About Us",
      link: "",
    },
    {
      label: "BrighterVPN Reviews",
      link: "",
    },
  ],
};

export const footer_information_links = {
  title: "Information",
  content: [
    {
      label: "Privacy Policy",
      link: "/privacy-policy",
    },
    {
      label: "Refund Policy",
      link: "",
    },
    {
      label: "Terms of Service",
      link: "",
    },
  ],
};

export const footer_help_links = {
  title: "Help",
  content: [
    {
      label: "Support Center",
      link: "",
    },
    {
      label: "VPN Setup Guides",
      link: "",
    },
    {
      label: "Contact Us",
      link: "",
    },
  ],
};

export const footer_programs_links = {
  title: "Programs",
  content: [
    {
      label: "VPN Affiliate Program",
      link: "",
    },
    {
      label: "Student Discount",
      link: "",
    },
    {
      label: "Family Plan",
      link: "",
    },
  ],
};

export const footer_business_links = {
  title: "VPN For Business",
  content: [
    {
      label: "VPN for Teams",
      link: "",
    },
    {
      label: "Developers (API)",
      link: "",
    },
    {
      label: "White Label VPN",
      link: "",
    },
    {
      label: "VPN Resellers Program",
      link: "",
    },
  ],
};

export const footer_follow_links = {
  title: "Follow Us",
  content: [
    {
      icon: FacebookSvg,
      label: "Facebook",
      link: "",
    },
    {
      icon: TwitterSvg,
      label: "Twitter",
      link: "",
    },
    {
      icon: InstagramSvg,
      label: "Instagram",
      link: "",
    },
    {
      icon: DiscordSvg,
      label: "Discord",
      link: "",
    },
    {
      icon: LinkedinSvg,
      label: "LinkedIn",
      link: "",
    },
  ],
};

export const footer_download_links = {
  title: "Download",
  content: [
    {
      icon: GoogleIcon,
      label: "google store",
      link: "",
    },
    {
      icon: AppIcon,
      label: "app store",
      link: "",
    },
  ],
};
