import "./App.css";
import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { photo_data } from "./config/const";
import { loadImages } from "./utils/loading";
import Loading from "./components/loading";

const Home = React.lazy(() => import("./page/home"));
const Privacy = React.lazy(() => import("./page/privacy"));
const Pricing = React.lazy(() => import("./page/pricing"));
const Servers = React.lazy(() => import("./page/servers"));
const WhatIsVPN = React.lazy(() => import("./page/whatisvpn"));

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadImages(photo_data)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Loading Image Error: ", error);
        setIsLoading(true);
      });
  }, []);

  if (isLoading) {
    return (
      <Loading />
    );
  }

  return (
    <Router>
      <Suspense fallback={
        <Loading />
      }>
        {/* <Header /> */}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/marketing" element={<Navigate to="/" />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/servers" element={<Servers />} />
            <Route path="/what-is-vpn" element={<WhatIsVPN />} />
          </Routes>
        </main>
        {/* <Footer /> */}
      </Suspense>
    </Router>
  );
}

export default App;
